import React, { useState, useEffect } from "react";
import axios from "axios";
import DescriptionClips from "./description_clips";

export default function SavedReels() {
  const [savedPosts, setSavedPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true);
//   const backendUrl = "http://127.0.0.1:8080"; 
  const backendUrl = 'https://socialoha-server-0b454a3e2f86.herokuapp.com';

  // Fetch saved reels from the backend using axios
  const fetchSavedReels = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/queries/saved_reels/21`); 
      setSavedPosts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching saved reels:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedReels();
  }, []);

  useEffect(() => {
    if (!selectedPost) {
      fetchSavedReels(); // Re-fetch saved reels when going back
    }
  }, [selectedPost]);

  const handleClick = (post) => {
    setSelectedPost(post);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-8">
      {!selectedPost ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {savedPosts.map((post, index) => (
            <div
              key={index}
              className="bg-white shadow-lg p-4 rounded-lg hover:shadow-xl cursor-pointer"
              onClick={() => handleClick(post)}
            >
              <h2 className="text-lg font-semibold">{post.title}</h2>
              <p className="mt-2 text-gray-500">{post.summary}</p>
              <p className="mt-2 text-gray-400">Duration: {post.duration} seconds</p>
            </div>
          ))}
        </div>
      ) : (
        <div>
            <button
            onClick={() => setSelectedPost(null)}
            className="mb-4 text-blue-500 text-base px-3 py-2 border border-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-200"
            >
            Back
            </button>

          {/* Reuse your StepTwo component */}
          <DescriptionClips formData={selectedPost} setSelectedPost={setSelectedPost} />
          </div>
      )}
    </div>
  );
}
